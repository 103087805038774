import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/PrimaryBox';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud Alexa Support",
  "path": "/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/",
  "dateChanged": "2020-11-06",
  "author": "Mike Polinowski",
  "excerpt": "How to Use INSTAR Cloud Alexa Skill",
  "image": "./MD_SearchThumb_Cloud_Alexa.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Alexa.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='INSTAR Cloud Alexa Support' dateChanged='2020-11-06' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to Use INSTAR Cloud Alexa Skill' image='/images/Search/MD_SearchThumb_Cloud_Alexa.png' twitter='/images/Search/MD_SearchThumb_Cloud_Alexa.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/' crumbLabel="Alexa" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h2>
    <h3 {...{
      "id": "alexa-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alexa-support",
        "aria-label": "alexa support permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alexa Support`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/b17f8/INSTAR_Cloud_Alexa_Skill_00-EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAbbwaiP/xAAXEAADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEBAAEFApqoMbMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAAhBR/9oACAEBAAY/ArPsBef/xAAZEAACAwEAAAAAAAAAAAAAAAABEQAhMRD/2gAIAQEAAT8hSyZYi4MYrOP/2gAMAwEAAgADAAAAEPPf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QiP/EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCsrP/EABoQAQADAAMAAAAAAAAAAAAAAAEAESExcYH/2gAIAQEAAT8QaXZAaB6dwzGSovmAo1n/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/417fcb8d0a1c38abca072a4d0f2d597d/e4706/INSTAR_Cloud_Alexa_Skill_00-EN.avif 230w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/d1af7/INSTAR_Cloud_Alexa_Skill_00-EN.avif 460w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/7f308/INSTAR_Cloud_Alexa_Skill_00-EN.avif 920w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/e1c99/INSTAR_Cloud_Alexa_Skill_00-EN.avif 1380w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/2babf/INSTAR_Cloud_Alexa_Skill_00-EN.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/417fcb8d0a1c38abca072a4d0f2d597d/a0b58/INSTAR_Cloud_Alexa_Skill_00-EN.webp 230w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/bc10c/INSTAR_Cloud_Alexa_Skill_00-EN.webp 460w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/966d8/INSTAR_Cloud_Alexa_Skill_00-EN.webp 920w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/445df/INSTAR_Cloud_Alexa_Skill_00-EN.webp 1380w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/fad48/INSTAR_Cloud_Alexa_Skill_00-EN.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/417fcb8d0a1c38abca072a4d0f2d597d/e83b4/INSTAR_Cloud_Alexa_Skill_00-EN.jpg 230w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/e41a8/INSTAR_Cloud_Alexa_Skill_00-EN.jpg 460w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/4d836/INSTAR_Cloud_Alexa_Skill_00-EN.jpg 920w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/89b2d/INSTAR_Cloud_Alexa_Skill_00-EN.jpg 1380w", "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/b17f8/INSTAR_Cloud_Alexa_Skill_00-EN.jpg 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/417fcb8d0a1c38abca072a4d0f2d597d/4d836/INSTAR_Cloud_Alexa_Skill_00-EN.jpg",
              "alt": "INSTAR Cloud Alexa Skill",
              "title": "INSTAR Cloud Alexa Skill",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`INSTAR Alexa Skill`}</strong>{` now combines a `}<strong parentName="p">{`Smart-Home Skill`}</strong>{` that allows you to add your camera as IP cameras in Alexa, and the `}<strong parentName="p">{`Cloud Skill`}</strong>{`, to can query alarm recordings from your INSTAR Cloud account - as well as some other filtering options for your recordings.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The `}<strong parentName="p">{`Smart-Home Skill`}</strong>{` gives you access to a range of camera functions. For example, ask Alexa to show you the live video from camera XYZ, activate the motion detection of your camera, ask for the number of alarms for specific cameras or activate / deactivate the alarm email notifications and object detection for your cameras. The prerequisite is - due to the video function - an echo spot or echo show (Alexa device with display).`}</p>
    <p>{`With the `}<strong parentName="p">{`INSTAR Cloud Skill`}</strong>{` you can play back and filter alarm recordings from your cloud account directly on your Alexa device (only devices with displays are supported). To use this capability you must have an INSTAR Cloud Account, which you can register at (`}<OutboundLink href="https://cloud.instar.de" mdxType="OutboundLink">{`cloud.instar.de`}</OutboundLink>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You can thus display all alarms of a specific camera. Or, for example, retrieve only the last 3 alarms, recordings with a person, a vehicle or an animal.`}</p>
    </blockquote>
    <p>{`Once you have set up your camera to upload alarm videos to the INSTAR cloud, you can use Alexa to add your cameras found as smart home devices. You can then:`}</p>
    <ul>
      <li parentName="ul">{`View the camera live video`}</li>
      <li parentName="ul">{`Activate/deactivate the alarm`}</li>
      <li parentName="ul">{`Display alarm videos based on specific filters from the INSTAR Cloud`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`You can also retrieve some account relevant information such as the available storage space, the number of cameras in your account and other information.`}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul">{`INSTAR Alexa Skill User Guide`}
        <ul parentName="li">
          <li parentName="ul">
            <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/" mdxType="Link">Adding your P2P ID to the INSTAR Cloud platform</Link>
          </li>
          <li parentName="ul">
            <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/" mdxType="Link">Troubleshooting</Link>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/" mdxType="Link">INSTAR Alexa Cloud Commands</Link>
      </li>
      <li parentName="ul">
        <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Camera_Commands/" mdxType="Link">INSTAR Alexa Camera Commands</Link>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`By adding your camera's P2P Information to your Account, you give your permission to the INSTAR Camera Skill to control your camera through the P2P protocol. The necessary access information will be stored inside your INSTAR Cloud Account and not be forwarded to third-parties.`}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "example-use-cases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-use-cases",
        "aria-label": "example use cases permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Use Cases`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bba6f4f760a02619657c93f78b395176/b17f8/INSTAR_Cloud_Alexa_Skill_15-EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABfYj0ZejAf//EABoQAQEAAgMAAAAAAAAAAAAAAAIBAAMSEyL/2gAIAQEAAQUCm/Coo36ZnZqnEUy5/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAaEAACAgMAAAAAAAAAAAAAAAAAARARQaHh/9oACAEBAAY/Aqei0YOlKP/EABoQAQADAQEBAAAAAAAAAAAAAAEAESExUYH/2gAIAQEAAT8hoUWzxNnU+xigfUsO6bqAHDvYlaT/2gAMAwEAAgADAAAAEOTv/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qsf/EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBQij//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhUWFxwf/aAAgBAQABPxAnpVjtCXsNaBKsIjTktQAdfI5soYC89ywkS6Tr5Zu09tn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bba6f4f760a02619657c93f78b395176/e4706/INSTAR_Cloud_Alexa_Skill_15-EN.avif 230w", "/en/static/bba6f4f760a02619657c93f78b395176/d1af7/INSTAR_Cloud_Alexa_Skill_15-EN.avif 460w", "/en/static/bba6f4f760a02619657c93f78b395176/7f308/INSTAR_Cloud_Alexa_Skill_15-EN.avif 920w", "/en/static/bba6f4f760a02619657c93f78b395176/e1c99/INSTAR_Cloud_Alexa_Skill_15-EN.avif 1380w", "/en/static/bba6f4f760a02619657c93f78b395176/2babf/INSTAR_Cloud_Alexa_Skill_15-EN.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bba6f4f760a02619657c93f78b395176/a0b58/INSTAR_Cloud_Alexa_Skill_15-EN.webp 230w", "/en/static/bba6f4f760a02619657c93f78b395176/bc10c/INSTAR_Cloud_Alexa_Skill_15-EN.webp 460w", "/en/static/bba6f4f760a02619657c93f78b395176/966d8/INSTAR_Cloud_Alexa_Skill_15-EN.webp 920w", "/en/static/bba6f4f760a02619657c93f78b395176/445df/INSTAR_Cloud_Alexa_Skill_15-EN.webp 1380w", "/en/static/bba6f4f760a02619657c93f78b395176/fad48/INSTAR_Cloud_Alexa_Skill_15-EN.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bba6f4f760a02619657c93f78b395176/e83b4/INSTAR_Cloud_Alexa_Skill_15-EN.jpg 230w", "/en/static/bba6f4f760a02619657c93f78b395176/e41a8/INSTAR_Cloud_Alexa_Skill_15-EN.jpg 460w", "/en/static/bba6f4f760a02619657c93f78b395176/4d836/INSTAR_Cloud_Alexa_Skill_15-EN.jpg 920w", "/en/static/bba6f4f760a02619657c93f78b395176/89b2d/INSTAR_Cloud_Alexa_Skill_15-EN.jpg 1380w", "/en/static/bba6f4f760a02619657c93f78b395176/b17f8/INSTAR_Cloud_Alexa_Skill_15-EN.jpg 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bba6f4f760a02619657c93f78b395176/4d836/INSTAR_Cloud_Alexa_Skill_15-EN.jpg",
              "alt": "INSTAR Cloud Alexa Skill",
              "title": "INSTAR Cloud Alexa Skill",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/87a30b61c4cf672be736f9bd5b493cf6/29007/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACpklEQVQoz4WS20uTcRjH38BC7F+INCQzqIugoi4iwpJOWnlRF0lJJ5CohCikCLqw7ELQDnjIQ8u5ZZlODSoPU2caqfMwl5u1lqekg2nb3Hzfd26/T7jZRRfWFz48V8+H5+F5JAAhxJIsJBgI4LBZsA/2MGzt5cP7Pj4O9eO0DTBit/DZbmHSaeN+aRbSn6Z/JRgM4By2LooG+GgL88lmwWm3MOGwYe42suFINNJCw6zXx4zLjdfnw+2ZxeX28MvtZtbrDQn9fj+trw001lXypukFPR1G+t+ZsJo7sPZ0MNzfRVtLLcevJYeFQw4n7V29dA9Y6TT3Y/3gYOLrN6ZmXCGhoigY9CVUPS7AoCumwaCny9SI+W0rg10mhnraGexup62hekEokD0uZmemcU//xDX1A9ntYs71CwLz4Z39CtUVxTzV5FNTXkBTSzM3nhhJ13WSqu3ltK4Xw8AozbVPkKb9gkGvYMgHtrkw730wLEO7K4jZIxjxKNRoi9A+zKO+ohDdyyaO3Srm7PmrJF3KJi7LyO3nJl7p8pFqpwLs6pPZ3/GNxLYJEk2TJLZOsMc4yt6WMRL6ZG46fNRVFKHJz6GuUsu9skwOnYnnxNGNpJ3aQdqNkzzSF1GtKUSye4OUTM5T/kVBM+ZFMz4XZszL43EfZV8DNH+fo7L0PiV3s6kqyKK+tgTts1z0dQ94qL+DtjIPXX42+lvpoaOI/+JXhKYwV2RnXhCpW6LFie3x4tzOjeLi7k3i8v6tImPfZnF22ypxJWGNCP1hQAiCi/UvgiJklBWZorw7XEs/TuLqCPatXsaBmAUkDsZIJMVIHI5dTkpsRGhCdSmEEKGqyLKak3VdzUhLUZPjo9TD66PUQ+tXqsnxK9WkdVFq0tpINTl2hZoSF6n+BhsexFOoallvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87a30b61c4cf672be736f9bd5b493cf6/e4706/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.avif 230w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/d1af7/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.avif 460w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/7f308/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.avif 920w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/e1c99/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.avif 1380w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/2babf/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/87a30b61c4cf672be736f9bd5b493cf6/a0b58/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.webp 230w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/bc10c/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.webp 460w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/966d8/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.webp 920w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/445df/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.webp 1380w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/fad48/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87a30b61c4cf672be736f9bd5b493cf6/81c8e/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.png 230w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/08a84/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.png 460w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/c0255/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.png 920w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/b1001/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.png 1380w", "/en/static/87a30b61c4cf672be736f9bd5b493cf6/29007/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/87a30b61c4cf672be736f9bd5b493cf6/c0255/INSTAR_Cloud_Alexa_Skill_19-EN.jpg.png",
              "alt": "INSTAR Cloud Alexa Skill",
              "title": "INSTAR Cloud Alexa Skill",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b466ae7b535456195e222ef5c9c5227/b17f8/INSTAR_Cloud_Alexa_Skill_21-EN.png.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAG2lGWG0Bv/xAAaEAADAQADAAAAAAAAAAAAAAABAgMREhMh/9oACAEBAAEFAjcq/IgXTKFRtSe23r//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQMBAT8BjL//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGhAAAgIDAAAAAAAAAAAAAAAAABEQIQIScf/aAAgBAQAGPwItKNlZh2P/xAAYEAEAAwEAAAAAAAAAAAAAAAABABEhMf/aAAgBAQABPyFoJjXY2FQ63LWsHScurlwRjiBhFXZ//9oADAMBAAIAAwAAABBIH//EABcRAAMBAAAAAAAAAAAAAAAAAAARIQH/2gAIAQMBAT8QqsTT/8QAFhEBAQEAAAAAAAAAAAAAAAAAERAh/9oACAECAQE/EEyP/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAMXEhUZH/2gAIAQEAAT8QArZRRBzEukJ8zz0cnmPGWE5QzyGXqCHsbTCTUle3f//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b466ae7b535456195e222ef5c9c5227/e4706/INSTAR_Cloud_Alexa_Skill_21-EN.png.avif 230w", "/en/static/4b466ae7b535456195e222ef5c9c5227/d1af7/INSTAR_Cloud_Alexa_Skill_21-EN.png.avif 460w", "/en/static/4b466ae7b535456195e222ef5c9c5227/7f308/INSTAR_Cloud_Alexa_Skill_21-EN.png.avif 920w", "/en/static/4b466ae7b535456195e222ef5c9c5227/e1c99/INSTAR_Cloud_Alexa_Skill_21-EN.png.avif 1380w", "/en/static/4b466ae7b535456195e222ef5c9c5227/2babf/INSTAR_Cloud_Alexa_Skill_21-EN.png.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b466ae7b535456195e222ef5c9c5227/a0b58/INSTAR_Cloud_Alexa_Skill_21-EN.png.webp 230w", "/en/static/4b466ae7b535456195e222ef5c9c5227/bc10c/INSTAR_Cloud_Alexa_Skill_21-EN.png.webp 460w", "/en/static/4b466ae7b535456195e222ef5c9c5227/966d8/INSTAR_Cloud_Alexa_Skill_21-EN.png.webp 920w", "/en/static/4b466ae7b535456195e222ef5c9c5227/445df/INSTAR_Cloud_Alexa_Skill_21-EN.png.webp 1380w", "/en/static/4b466ae7b535456195e222ef5c9c5227/fad48/INSTAR_Cloud_Alexa_Skill_21-EN.png.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b466ae7b535456195e222ef5c9c5227/e83b4/INSTAR_Cloud_Alexa_Skill_21-EN.png.jpg 230w", "/en/static/4b466ae7b535456195e222ef5c9c5227/e41a8/INSTAR_Cloud_Alexa_Skill_21-EN.png.jpg 460w", "/en/static/4b466ae7b535456195e222ef5c9c5227/4d836/INSTAR_Cloud_Alexa_Skill_21-EN.png.jpg 920w", "/en/static/4b466ae7b535456195e222ef5c9c5227/89b2d/INSTAR_Cloud_Alexa_Skill_21-EN.png.jpg 1380w", "/en/static/4b466ae7b535456195e222ef5c9c5227/b17f8/INSTAR_Cloud_Alexa_Skill_21-EN.png.jpg 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b466ae7b535456195e222ef5c9c5227/4d836/INSTAR_Cloud_Alexa_Skill_21-EN.png.jpg",
              "alt": "INSTAR Cloud Alexa Skill",
              "title": "INSTAR Cloud Alexa Skill",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      